.calculatorContainer {
    width: fit-content;
    background-color: rgb(3, 3, 90);
    border-radius: 0.75rem;
    border: solid 7px rgb(3, 3, 90);
    margin: 200px auto;
    text-align: center;
    font-size: larger;
}

.calculatorFunctions {
    width: 400px;
    background-color: white;
    padding: 30px;
}

.tipTotals {
    display: flex;
    justify-content: space-between;
}

.hidden {
    visibility: hidden;
}

.calculatorFunctions > input {
    height: 50px;
    width: 400px;
    font-size: xx-large;
    text-align: right;
    padding: 10px;
}

.tipButtons {
    display: flex;
    justify-content: space-evenly;
}

.tipButtons > button {
    font-size: x-large;
    width: 120px;
    border-radius: 5px;
    background-color: rgb(205, 202, 198);
    padding: 8px;
}

.tipButtons > button:hover {
    background-color: rgb(136, 220, 248);
}

.customTipButton {
    width: 360px;
    height: 50px;
    font-size: x-large;
    margin-top: 10px;
    border-radius: 5px;
    background-color: rgb(205, 202, 198);
}

.customTipButton:hover {
    background-color: rgb(136, 220, 248);
}

.splitContainer {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    padding-right: 6px;
    list-style: none;
}

.splitContainer > li {
    background-color: rgb(202, 202, 246);
    text-align: center;
    padding-top: 12px;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.splitContainer > li:hover {
    background-color: rgb(108, 108, 238);
}

.finalOutput {
    width: fit-content;
    padding: 20px;
    margin-left: 20%;
    text-align: center;
}

.finalOutput > p {
    color: white;
}

.finalOutput > p span {
    color: rgb(136, 220, 248);
    font-size: 50px;
}

.clicked {
    border: solid 4px rgb(3, 3, 90);
}
